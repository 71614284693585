import Swiper from 'swiper';

import 'swiper/css';
import { Navigation } from 'swiper/modules';


export const setupSlider = () => {
    const sliderVertical = document.querySelector('.slider-vertical');
    
    if (sliderVertical) {
        const swiper = new Swiper(sliderVertical, {
            modules: [Navigation],

            direction: 'vertical',
            slidesPerView: 1.1,
            spaceBetween: 8,

            navigation: {
                nextEl: '.slider-vertical__button-next',
                prevEl: '.slider-vertical__button-prev',
            },

            breakpoints: {
                900: {
                    direction: 'horizontal',
                    spaceBetween: 32,
                    slidesPerView: 1.05,
                }
            }
        });
    }
}