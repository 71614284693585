export const setupSmoothScroll = () => {
    const links = document.querySelectorAll(".navigation-subsite__nav .button");

    links.forEach(anchor => {
        anchor.addEventListener('click', function (event) {
            if (this.hash !== "") {
                event.preventDefault();

                const hash = this.hash;

                const targetElement = document.querySelector(hash);

                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    setTimeout(() => {
                        window.location.hash = hash;
                    }, 800);
                }
            }
        });
    });
}