import barba from '@barba/core';
import { setupAccordion } from './accordion';
import { setupContactPopup } from './contact-popup';
import { setupNav } from './nav';
import { setupModulesNav } from './modules-nav';
import { setupLightbox } from './lightbox';
import { setupSlider } from './slider';
import { setupSmoothScroll } from './smooth-scroll';
import { setupTypewriter } from './typewriter';
import { setupNote } from './note';

let cleanup = {
    typewriter: null,
    contactPopup: null,
    accordion: null,
    nav: null,
    modulesNav: null,
    lightbox: null,
    slider: null,
    smoothScroll: null,
    note: null
};

const cleanupAllFeatures = () => {
    Object.keys(cleanup).forEach(key => {
        if (typeof cleanup[key] === 'function') {
            cleanup[key]();
            cleanup[key] = null;
        }
    });
};

// Add this function to manually initialize Sprig
const initializeSprig = () => {
    // Check if we need to manually load Sprig
    if (!window.Sprig) {
        // Option 1: Try to find and execute Sprig's script
        const sprigScripts = document.querySelectorAll('script[src*="sprig"]');
        if (sprigScripts.length > 0) {
            // Create a new script element to reload Sprig
            const script = document.createElement('script');
            script.src = sprigScripts[0].src;
            script.onload = () => {
                if (window.Sprig) {
                    window.Sprig.reinit();
                }
            };
            document.head.appendChild(script);
        } else {            
            // Add click handlers to filter radio buttons
            document.querySelectorAll('.filter__radio').forEach(radio => {
                radio.addEventListener('change', function() {
                    if (this.checked) {
                        const url = this.getAttribute('s-get');
                        if (url) {
                            window.location.href = url;
                        }
                    }
                });
            });
        }
    } else if (window.Sprig) {
        window.Sprig.reinit();
    }
};

const setupFilterFallback = () => {
    // Function to attach event listeners to filter elements
    const attachFilterListeners = () => {
        document.querySelectorAll('.filter__radio').forEach(radio => {
            // Skip if already processed
            if (radio.dataset.processed) return;
            
            // Mark as processed to avoid duplicate listeners
            radio.dataset.processed = 'true';
            
            // Remove Sprig attributes to prevent conflicts
            radio.removeAttribute('sprig');
            
            // Add our own event handler
            radio.addEventListener('change', function(event) {
                // Prevent default behavior
                event.preventDefault();
                event.stopPropagation();
                
                if (this.checked) {
                    const url = this.getAttribute('s-get');
                    if (url) {                        
                        // Update URL without triggering navigation
                        const fullUrl = window.location.origin + window.location.pathname + url;
                        history.pushState({}, '', fullUrl);
                        
                        // Fetch the filtered content directly
                        fetch(fullUrl)
                            .then(response => response.text())
                            .then(html => {
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(html, 'text/html');
                                
                                // Find the archive container in the fetched HTML
                                const newArchive = doc.querySelector('.archive');
                                
                                // Replace the current archive with the new one
                                if (newArchive) {
                                    const currentArchive = document.querySelector('.archive');
                                    if (currentArchive) {
                                        currentArchive.outerHTML = newArchive.outerHTML;
                                        
                                        // Important: Reattach event listeners to the new filter elements
                                        attachFilterListeners();
                                    }
                                }
                            })
                            .catch(error => {
                                console.error('Error fetching filtered content:', error);
                                // If fetch fails, reload the page
                                window.location.href = fullUrl;
                            });
                    }
                }
            });
        });
    };
    
    // Initial attachment of event listeners
    attachFilterListeners();
    
    // Also handle the form to prevent submission
    const filterForm = document.querySelector('.filter__form');
    if (filterForm && !filterForm.dataset.processed) {
        filterForm.dataset.processed = 'true';
        filterForm.addEventListener('submit', function(event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        });
    }
};

const setupScripts = async () => {
    cleanupAllFeatures();

    cleanup.contactPopup = setupContactPopup();
    cleanup.accordion = setupAccordion();
    cleanup.nav = setupNav();
    cleanup.modulesNav = setupModulesNav();
    cleanup.lightbox = setupLightbox();
    cleanup.slider = setupSlider();
    cleanup.smoothScroll = setupSmoothScroll();
    cleanup.note = setupNote();
    cleanup.typewriter = setupTypewriter();
    
    // Check if we're on a page with filters
    if (document.querySelector('.filter')) {
        setupFilterFallback();
    }
};

const preloadContainer = document.createElement('div');
preloadContainer.style.display = 'none';
document.body.appendChild(preloadContainer);

async function preloadPage(url) {
    try {
        const response = await fetch(url);
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.querySelector('[data-barba="container"]').innerHTML;
    } catch (error) {
        return null;
    }
}

function isHomePage(url) {
    const path = new URL(url).pathname;
    return path === '/';
}

const managePageVisibility = (show = true) => {
    const container = document.querySelector('[data-barba="container"]');

    if (container) {
        container.style.opacity = show ? '1' : '0';
        container.style.transition = 'opacity 0.3s ease-in-out';
    }
};


document.addEventListener('DOMContentLoaded', () => {
    // Check if we're on a filter page before initializing Barba
    if (document.querySelector('.filter')) {
        setupScripts();
        return; // Don't initialize Barba at all
    }
    
    // Rest of your Barba initialization code...
    setupScripts();

    const pageTransitionRight = document.getElementById('page-transition__right')
    const header = document.getElementById('header');

    if (!isHomePage(window.location.href) && !document.body.classList.contains('page-404')) {
        header.classList.add('header--hidden');
        header.style.transform = 'translateX(-115%)';
        pageTransitionRight.classList.add('page-transition__right--hidden');
        // pageTransitionRight.style.transform = isMobile ? 'translateY(-100%)' : 'translateX(115%)';
        pageTransitionRight.style.transform = 'translateX(115%)';
    } else {
        pageTransitionRight.classList.remove('page-transition__right--hidden');
    }

    barba.init({
        transitions: [{
            name: 'curtain-transition',
            async beforeLeave(data) {
                this.nextHash = data.next.url.hash;
                cleanupAllFeatures();
                const pageTransitionRight = document.getElementById('page-transition__right');
                document.body.classList.remove('no-scroll', 'scroll-locked');

                managePageVisibility(false);

                if (cleanup.typewriter) {
                    cleanup.typewriter();
                    cleanup.typewriter = null;
                }

                if (!isHomePage(data.next.url.href)) {
                    pageTransitionRight.classList.remove('page-transition__right--hidden')
                }

                await new Promise(resolve => setTimeout(resolve, 300));
            },
            async enter(data) {
            },
            async after(data) {
                document.body.classList.remove('no-scroll', 'scroll-locked');
                const header = document.getElementById('header');
                const pageTransitionRight = document.getElementById('page-transition__right');
                const nav = document.querySelector('.nav');
                const contactPopup = document.querySelector('#contact-popup');
                const headerBurger = document.querySelector('.header__burger')

                setupScripts();
                contactPopup.classList.remove('contact-popup--visible');

                if (isHomePage(data.next.url.href)) {
                    header.classList.remove('header--hidden');
                    headerBurger.classList.remove('header__burger--active')
                    header.style.transform = 'translateX(0)';
                    pageTransitionRight.classList.remove('page-transition__right--hidden');
                    pageTransitionRight.style.transform = 'translateX(0)';
                } else {
                    header.classList.add('header--hidden');
                    header.style.transform = 'translateX(-115%)';
                    pageTransitionRight.classList.add('page-transition__right--hidden');
                    pageTransitionRight.style.transform = 'translateX(115%)';
                }

                nav.classList.add('nav--hidden');

                setTimeout(() => {
                    if (this.nextHash) {
                        history.replaceState(null, null, `${window.location.pathname}#${this.nextHash}`);
                        const element = document.querySelector(`#${this.nextHash}`);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    } else {
                        window.scrollTo(0, 0);
                    }
                    managePageVisibility(true);
                }, 100);

                // Additional check: If we're on the filter page, make sure Sprig is initialized
                // with a slight delay to ensure DOM is fully updated
                if (document.querySelector('.filter')) {
                    setTimeout(() => {
                        initializeSprig();
                    }, 300);
                }
            }
        }],
        preventRunning: true,
        scrollTo: false,
        // Prevent Barba from handling filter pages
        prevent: ({ el, href, url }) => {
            // Don't let Barba handle elements with Sprig attributes
            if (el.hasAttribute('sprig') || 
                el.closest('[sprig]') !== null ||
                el.hasAttribute('s-get') ||
                el.hasAttribute('s-post') ||
                el.hasAttribute('s-method')) {
                return true;
            }
            
            // Check if the target page is a filter page
            if (href && (href.includes('/archiv') || href.includes('?segment='))) {
                return true;
            }
            
            // Check if the current page has filters
            if (document.querySelector('.filter') !== null) {
                return true;
            }
            
            return false;
        }
    });

    // Prevent Barba from handling hash changes
    document.addEventListener('click', (event) => {
        const link = event.target.closest('a');
        if (link && link.hash && link.hostname === window.location.hostname && link.pathname === window.location.pathname) {
            event.preventDefault();
            const element = document.querySelector(link.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                history.replaceState(null, null, link.hash);
            }
        }
    }, false);

    // Add this to prevent Barba from handling Sprig requests
    barba.hooks.beforeLeave((data) => {
        // Check if this is a Sprig request
        const isSprigRequest = data.trigger && 
                              (data.trigger.hasAttribute('sprig') || 
                               data.trigger.closest('[sprig]'));
        
        if (isSprigRequest) {
            return false;
        }
    });
});

// Optimize prefetch with intersection observer
const prefetchObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const link = entry.target;
                if (link.href && link.href.startsWith(window.location.origin)) {
                    preloadPage(link.href);
                }
                prefetchObserver.unobserve(link);
            }
        });
    },
    { rootMargin: '50px' }
);

// Initialize observation of all internal links
document.querySelectorAll('a[href^="' + window.location.origin + '"]').forEach(link => {
    prefetchObserver.observe(link);
});