// Constants for CSS classes and selectors
const setTransitionStyles = () => {
  const header = document.getElementById('header');
  const pageTransitionRight = document.getElementById('page-transition__right');
  const transitionStyle = 'transform 1500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);';
  
  header.style.transition = transitionStyle;
  pageTransitionRight.style.transition = transitionStyle;
};

const SELECTORS = {
  header: '#header',
  nav: '#main-navigation',
  headerBurger: '.header__burger',
  pageBurger: '.page-burger',
  productionBurger: '.navigation-production__burger',
  subsiteNavTrigger: '.navigation-subsite__nav-desktop',
  navMobileItems: '.nav__primary-item.type-xxl > .text-link',
  pageTransitionRight: '.page-transition__right'
};

const CLASSES = {
  headerBurgerActive: 'header__burger--active',
  pageBurgerActive: 'navigation-subsite__burger--active',
  navHidden: 'nav--hidden',
  headerHidden: 'header--hidden',
  noScroll: 'no-scroll'
};

const toggleNavState = (burger, burgerActiveClass, nav, shouldToggleScroll = false) => {

  burger.classList.toggle(burgerActiveClass);
  nav.classList.toggle(CLASSES.navHidden);
  nav.setAttribute('aria-expanded', !nav.classList.contains(CLASSES.navHidden));

  if (shouldToggleScroll) {
    document.body.classList.toggle(CLASSES.noScroll);
  }
};

const setupPageNavigation = (nav, headerBurgerPage) => {
  if (!nav || !headerBurgerPage) return;

  headerBurgerPage.addEventListener('click', () => {
    toggleNavState(headerBurgerPage, CLASSES.pageBurgerActive, nav);
  });
};

const setupSubsiteNavigation = (header,pageTransitionRight, subsiteNavTrigger) => {
  if (!header || !subsiteNavTrigger) return;

  subsiteNavTrigger.addEventListener('click', () => {
    header.classList.toggle(CLASSES.headerHidden);
    header.style.transform = "translateX(0)";

    header.classList.remove('header--hidden');
    pageTransitionRight.classList.remove('page-transition__right--hidden')
    setTransitionStyles();
    header.style.transform = 'translateX(0)';
    pageTransitionRight.style.transform = 'translateX(0)';

  });
};

const setupNavItems = (navItems, nav) => {
  if (!navItems || !nav) return;

  navItems.forEach((navItem) => {
    navItem.addEventListener('click', () => {
      nav.classList.add('nav--hidden');
    });
  })
}

// Main setup function
export const setupNav = () => {
  const elements = {
    header: document.querySelector(SELECTORS.header),
    nav: document.querySelector(SELECTORS.nav),
    headerBurger: document.querySelector(SELECTORS.headerBurger),
    headerBurgerPage: document.querySelector(SELECTORS.pageBurger),
    subsiteNavTrigger: document.querySelector(SELECTORS.subsiteNavTrigger),
    pageTransitionRight: document.querySelector(SELECTORS.pageTransitionRight)
  };

  // Store event listener references
  const listeners = new Map();

  // Setup home navigation
  if (elements.nav && elements.headerBurger) {
    const homeNavHandler = () => {
      console.log('click');
      toggleNavState(elements.headerBurger, CLASSES.headerBurgerActive, elements.nav, true);
    };
    elements.headerBurger.addEventListener('click', homeNavHandler);
    listeners.set(elements.headerBurger, homeNavHandler);
  }

  setupNavItems(elements.navMobileItems, elements.nav);
  setupPageNavigation(elements.nav, elements.headerBurgerPage);
  setupSubsiteNavigation(elements.header, elements.pageTransitionRight, elements.subsiteNavTrigger);

  return () => {
    listeners.forEach((handler, element) => {
      if (element) {
        element.removeEventListener('click', handler);
      }
    });
    listeners.clear();
  };
};