export const setupContactPopup = () => {
    // Store the event listener functions so we can remove them later
    const handleClick = (popup) => () => {
        popup.classList.toggle('contact-popup--visible');
    };

    const handleClose = (popup) => () => {
        popup.classList.remove('contact-popup--visible');
    };

    // Cleanup function to remove old listeners
    const cleanup = () => {
        const oldButtons = document.querySelectorAll('[data-target="contact-popup"]');
        const oldPopup = document.getElementById('contact-popup');
        
        if (oldPopup) {
            const oldCloseButton = oldPopup.querySelector('.contact-popup__close');
            
            oldButtons?.forEach(button => {
                // Remove the old listener using the stored function reference
                const oldHandler = button._clickHandler;
                if (oldHandler) {
                    button.removeEventListener('click', oldHandler);
                    delete button._clickHandler;
                }
            });

            if (oldCloseButton) {
                const oldCloseHandler = oldCloseButton._closeHandler;
                if (oldCloseHandler) {
                    oldCloseButton.removeEventListener('click', oldCloseHandler);
                    delete oldCloseButton._closeHandler;
                }
            }
        }
    };

    // Clean up old listeners first
    cleanup();

    // Set up new listeners
    const contactButtons = document.querySelectorAll('[data-target="contact-popup"]');
    const contactPopup = document.getElementById('contact-popup');

    if (contactPopup) {
        const closeButton = contactPopup.querySelector('.contact-popup__close');

        contactButtons?.forEach(contactButton => {
            if (contactButton) {
                // Store the handler function reference on the element
                const clickHandler = handleClick(contactPopup);
                contactButton._clickHandler = clickHandler;
                contactButton.addEventListener('click', clickHandler);
            }
        });

        if (closeButton) {
            // Store the close handler function reference
            const closeHandler = handleClose(contactPopup);
            closeButton._closeHandler = closeHandler;
            closeButton.addEventListener('click', closeHandler);
        }
    }

    // Return cleanup function for external use if needed
    return cleanup;
};