import Swiper from 'swiper';
import 'swiper/css';

import { Navigation } from 'swiper/modules';

export const setupLightbox = () => {
    const triggers = document.querySelectorAll('.lightbox__trigger');
    const lightboxTriggerAreas = document.querySelectorAll('.lightbox__images');

    const calculateSlidesPerView = () => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        if (viewportWidth >= 900) {
            const slideHeight = 48;
            const gap = 10;
            const availableHeight = viewportHeight - 132;
            return Math.floor((availableHeight + gap) / (slideHeight + gap));
        } else {
            const outerPadding = 16;
            const slideWidth = 48;
            const gap = 10;
            const availableWidth = viewportWidth - outerPadding;
            return Math.floor((availableWidth + gap) / (slideWidth + gap));
        }
    };

    const closeLightbox = (lightbox, trigger) => {
        if (lightbox.swiperInstances) {
            const { swiper, swiper2 } = lightbox.swiperInstances;
            if (swiper) swiper.destroy(true, true);
            if (swiper2) swiper2.destroy(true, true);
            lightbox.swiperInstances = null;
        }

        document.body.classList.remove('no-scroll');

        // Remove inert attribute
        lightbox.setAttribute('inert', true);

        // Return focus to trigger element
        if (trigger && document.body.contains(trigger)) {
            trigger.focus();
        }
    };

    const initLightboxSlider = (lightbox) => {
        // Always destroy existing instances first
        if (lightbox.swiperInstances) {
            closeLightbox(lightbox);
        }

        const mySwiper = lightbox.querySelector('.mySwiper');
        const mySwiper2 = lightbox.querySelector('.mySwiper2');

        if (!mySwiper || !mySwiper2) return null;

        const swiper = new Swiper(mySwiper, {
            spaceBetween: 10,
            slidesPerView: calculateSlidesPerView(),
            freeMode: true,
            centeredSlides: true,
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            keyboard: {
                enabled: true
            },
            breakpoints: {
                900: {
                    direction: 'vertical'
                }
            },
            on: {
                click: () => {
                    swiper2.slideTo(swiper.clickedIndex);
                },
                slideChange: () => {
                    swiper.updateProgress();
                    swiper.updateSlidesClasses();
                }
            }
        });

        const swiper2 = new Swiper(mySwiper2, {
            modules: [Navigation],
            spaceBetween: 10,
            thumbs: {
                swiper: swiper
            },
            navigation: {
                nextEl: '.lightbox-overlay__button-next',
                prevEl: '.lightbox-overlay__button-prev'
            },
            on: {
                slideChange: function () {
                    const activeIndex = swiper2.activeIndex;
                    const activeSlide = swiper.slides[activeIndex];
                   
                    swiper.slides.forEach(slide => slide.classList.remove('swiper-slide-active'));
                    
                    if (activeSlide) {
                        activeSlide.classList.add('swiper-slide-active');
                    }

                    swiper.activeIndex = activeIndex;
                    swiper.updateProgress();
                    swiper.updateSlidesClasses();
                    swiper.slideTo(activeIndex);
                },
            }
        });


        const closeButton = lightbox.querySelector('.lightbox-overlay__close');
        if (closeButton) {
            const newCloseButton = closeButton.cloneNode(true);
            closeButton.parentNode.replaceChild(newCloseButton, closeButton);

            newCloseButton.addEventListener('click', () => {
                closeLightbox(lightbox, lightbox.previouslyFocused);
            });
        }

        return { swiper, swiper2 };
    };

    const setupLightboxTrigger = (trigger, lightboxOverlay) => {
        if (!lightboxOverlay) return;

        // Remove existing click listeners by cloning
        const newTrigger = trigger.cloneNode(true);
        trigger.parentNode.replaceChild(newTrigger, trigger);

        newTrigger.addEventListener('click', () => {
            lightboxOverlay.previouslyFocused = document.activeElement;
            lightboxOverlay.previouslyFocused = document.activeElement;

            lightboxOverlay.removeAttribute('inert');
            void lightboxOverlay.offsetHeight;
            document.body.classList.add('no-scroll');
            lightboxOverlay.swiperInstances = initLightboxSlider(lightboxOverlay);

            // Focus the close button
            const closeButton = lightboxOverlay.querySelector('.lightbox-overlay__close');
            if (closeButton) {
                closeButton.focus();
            }

        });

        // Handle outside clicks
        const handleOutsideClick = (event) => {
            if (event.target === lightboxOverlay) {
                closeLightbox(lightboxOverlay, lightboxOverlay.previouslyFocused);
            }
        };

        // Remove existing event listener and add new one
        lightboxOverlay.removeEventListener('click', handleOutsideClick);
        lightboxOverlay.addEventListener('click', handleOutsideClick);
    };

    // Setup for trigger areas
    lightboxTriggerAreas.forEach(triggerArea => {
        const lightboxId = triggerArea.getAttribute('data-lightbox-id');
        const lightboxOverlay = document.getElementById(`lightbox-${lightboxId}`);
        setupLightboxTrigger(triggerArea, lightboxOverlay);
    });

    // Setup for individual triggers
    triggers.forEach(trigger => {
        const lightboxId = trigger.getAttribute('data-lightbox-id');
        const lightboxOverlay = document.getElementById(`lightbox-${lightboxId}`);
        setupLightboxTrigger(trigger, lightboxOverlay);
    });

    // Cleanup on window resize
    window.addEventListener('resize', () => {
        const openLightbox = document.querySelector('.lightbox-overlay[aria-hidden="false"]');
        if (openLightbox && openLightbox.swiperInstances) {
            openLightbox.swiperInstances = initLightboxSlider(openLightbox);
        }
    });
};