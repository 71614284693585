export const setupAccordion = () => {
    function toggleAccordion() {
        const accordionTitles = document.querySelectorAll('.accordion-item__title');

        accordionTitles.forEach((title) => {
            const accordionWrapper = title.closest('.accordion-item');
            const accordionContent = accordionWrapper.querySelector('.accordion-item__content-wrapper');

            // Mark the accordion as ready for JavaScript
            accordionWrapper.classList.add('js-ready');
            accordionWrapper.classList.add('accordion-item--collapsed');

            // Initial state for the content
            if (accordionContent) {
                accordionContent.style.height = '0';
                accordionContent.style.overflow = 'hidden';
            }

            // Add click event listener to the title
            title.addEventListener('click', () => {
                const isExpanded = accordionWrapper.classList.contains('accordion-item--expanded');

                // Close all other accordions
                document.querySelectorAll('.accordion-item').forEach((wrapper) => {
                    if (wrapper !== accordionWrapper) {
                        wrapper.classList.remove('accordion-item--expanded');
                        wrapper.classList.add('accordion-item--collapsed');
                        const content = wrapper.querySelector('.accordion-item__content-wrapper');
                        if (content) content.style.height = '0';
                    }
                });

                // Toggle the clicked accordion
                if (accordionContent) {
                    if (isExpanded) {
                        accordionWrapper.classList.remove('accordion-item--expanded');
                        accordionWrapper.classList.add('accordion-item--collapsed');
                        accordionContent.style.height = '0';
                    } else {
                        accordionWrapper.classList.remove('accordion-item--collapsed');
                        accordionWrapper.classList.add('accordion-item--expanded');
                        accordionContent.style.height = accordionContent.scrollHeight + 'px';
                    }
                }
            });
        });
    }

    toggleAccordion();
}
