export const setupModulesNav = () => {
    const sections = document.querySelectorAll("main section");
    const links = document.querySelectorAll(".navigation-subsite__nav .button");

    if (links && sections) {
        const observerOptions = {
            root: null,
            threshold: 0.6,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Get the active section's id
                    const activeId = entry.target.id;

                    // Update the active class on links
                    links.forEach((link) => {
                        const href = link.getAttribute("href").substring(1);
                        if (href === activeId) {
                            link.classList.add("button--primary");
                        } else {
                            link.classList.remove("button--primary");
                            link.classList.add("button--inactive");
                        }
                    });
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sections.forEach((section) => observer.observe(section));
    }
}