import lottie from 'lottie-web';
// note.js
let currentAnimation = null; 

export const setupNote = () => {
    // Find all Lottie containers
    const lottieContainers = document.querySelectorAll('.lottie-container');
    if (!lottieContainers.length) return;

    // Clean up any existing animations
    if (currentAnimation) {
        currentAnimation.destroy();
        currentAnimation = null;
    }

    // Initialize animations for each container
    lottieContainers.forEach(lottieContainer => {
        lottieContainer.innerHTML = '';
        
        const lottieAnimationPath = lottieContainer.dataset.animationPath;
        const mainColor = lottieContainer.dataset.mainColor;
        
        // Configure Lottie with specific SVG preferences
        const animation = lottie.loadAnimation({
            container: lottieContainer,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: lottieAnimationPath,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice', 
                clearCanvas: true,
                progressiveLoad: false,
                hideOnTransparent: false
            }
        });

        // Store the animation reference
        if (!currentAnimation) {
            currentAnimation = animation;
        }

        // Set color variable
        document.documentElement.style.setProperty('--color--accent', mainColor);

        const removeClipPaths = () => {
            const svg = lottieContainer.querySelector('svg');
            if (svg) {
                svg.querySelectorAll('[clip-path]').forEach(element => {
                    element.removeAttribute('clip-path');
                });
                
                svg.querySelectorAll('clipPath').forEach(clipPath => {
                    clipPath.remove();
                });
                
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', '100%');
                svg.style.width = '100%';
                svg.style.height = '100%';
            }
        };

        animation.addEventListener('DOMLoaded', () => {
            removeClipPaths();
            
            const paths = lottieContainer.querySelectorAll('path');
            paths.forEach(path => {
                const currentFill = path.getAttribute('fill');
                if (currentFill === 'rgb(255,164,241)' || currentFill === 'rgb(255,142,224)') {
                    path.setAttribute('fill', 'var(--color--accent)');
                }
            });
        });

        animation.addEventListener('enterFrame', removeClipPaths);
    });

    return () => {
        if (currentAnimation) {
            currentAnimation.destroy();
            currentAnimation = null;
        }
    };
};