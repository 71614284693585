export const setupTypewriter = () => {
    let timeoutId = null;
    const typewriterElement = document.querySelector('.typewriter');

    if (!typewriterElement) return;

    const words = ['Erwachsenen', 'Jugend', 'Kinder'];
    let wordIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let typingSpeed = 200;
    let pauseAfterDeletion = false;
    let cycleCount = 0;
    const maxCycles = 1000;

    function type() {
        if (cycleCount === maxCycles && wordIndex === words.length - 1 && !isDeleting) {
            typewriterElement.style.borderRight = 'none';
            return;
        }

        const currentWord = words[wordIndex];

        if (pauseAfterDeletion) {
            pauseAfterDeletion = false;
            timeoutId = setTimeout(type, 500);
            return;
        }

        if (isDeleting) {
            typewriterElement.textContent = currentWord.substring(0, charIndex);
            charIndex--;
            typingSpeed = 100;
        } else {
            typewriterElement.textContent = currentWord.substring(0, charIndex + 1);
            charIndex++;
            typingSpeed = 200;
        }

        if (!isDeleting && charIndex === currentWord.length) {
            if (cycleCount === maxCycles - 1 && wordIndex === words.length - 1) {
                return;
            }
            isDeleting = true;
            typingSpeed = 1500;
        } else if (isDeleting && charIndex < 0) {
            isDeleting = false;
            wordIndex = (wordIndex + 1) % words.length;
            pauseAfterDeletion = true;

            if (wordIndex === 0) {
                cycleCount++;
            }
        }

        timeoutId = setTimeout(type, typingSpeed);
    }

    type();

    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (typewriterElement) {
            typewriterElement.textContent = '';
            typewriterElement.style.borderRight = '';
        }
    };
};